import styled from 'styled-components';

interface ScParagraphProps {
	color?: string;
	centered?: boolean;
}

export const ScParagraph = styled.div<ScParagraphProps>`
	color: ${({ theme, color }) => color || theme.colors.darkText};
	font: ${({ theme }) => theme.fonts.paragraph.small};
	text-align: ${({ centered }) => centered && 'center'};
	p {
		margin: 0;
		padding: 0;
	}
	b {
		font-family: 'GeomanistMedium';
	}
	a {
		color: ${({ theme, color }) => color || theme.colors.darkText};
		text-decoration: underline;
		cursor: pointer;
		&:hover {
			text-decoration: none;
		}
	}
	display: flex;
	flex-direction: column;
	gap: 5px;
	@media (min-width: 768px) {
		font: ${({ theme }) => theme.fonts.paragraph.medium};
		gap: 10px;
	}
	@media (min-width: 1220px) {
		font: ${({ theme }) => theme.fonts.paragraph.large};
		gap: 10px;
	}
	@media (min-width: 1440px) {
		font: ${({ theme }) => theme.fonts.paragraph.enormous};
	}
	@media (min-width: 1920px) {
		font: ${({ theme }) => theme.fonts.paragraph.xl};
	}
`;
